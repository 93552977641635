/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600&display=swap');

@font-face {
    font-family: HaloHandletter;
    src: url(/assets/fonts/HaloHandletter.otf);
  }
a,button,span,.btn{
    /* font-family: 'Montserrat'; */
    cursor: pointer; 
}
p,li,h1,h2,h3,h4,h5,h6,label,b{
  cursor: default;
}
body[appHighlight] {
  user-select: none; /* Disable text selection */
  -webkit-user-select: none;
  /* cursor: pointer; */
   /* Change cursor to 'not-allowed' */
    /* Optionally, add a background color to indicate non-interactivity */
}
.img-responsive{
  width: 100% !important;
}
.invUpload
{
  font-size: 0;
  cursor: pointer !important;
}
p,span{
    font-weight: 400;
    font-size: 16px;
    cursor:pointer;
}
[disabled],disabled,.disabled , .btn .disabled{
    cursor: not-allowed !important;
    opacity: 1.75 !important;
}
.border-radius-2{
  border-radius: 2rem!important;
}
.heads{
    color: #0EC6FB;
    font-size: 48px;
    font-weight: 700;
}
.sub-head{
    font-family: HaloHandletter;
    color: #0070BF;
    font-size: 64px;
    /* position: relative;
    bottom: 12px; */
}
.register{
    border: 2px solid #11C6FB;
    color: #11C6FB;
    padding: 12px 16px;
    font-weight: 600;
}
.btn-login{
    border-radius: 10px;
    border: 1px solid #215D88;
        padding: 12px 4.75rem;
    background: #215D88 0% 0% no-repeat padding-box;
    color: white;
}
.btn-signup{
  color: #fff;
      padding: 12px 4.75rem;
  background: #ed7c30;
  border: none;
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent; 
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #215d88; 
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #215d88 !important; 
}
.ngx-pagination {
  padding: 0;
}
.was-validated .form-control:valid, .form-control.is-invalid{
  border-color: transparent;
  background-position: right calc(1em + 0.1875rem) center;
}
.mob{
  display: none;
}
.overlay {
  position:fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 10001;
  cursor: pointer;
}
.text{
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 25px;
  /* color: white; */
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}
.dark-spinner{
  stroke: #fff!important;
}
.text ::ng-deep .mat-mdc-progress-spinner circle, .mat-mdc-progress-spinner circle {
  stroke: #fff;
}
.margin-top{
  /* margin-top: 84px !important; */
  height: 800px;
  overflow: scroll;
}
.ngx-pagination{
  padding-bottom: 3rem !important;
}
.mdc-label {
  font-size: 18px !important;
  font-family: 'Montserrat';
  font-weight: 500;
  text-transform: capitalize;
}
.toast-container {
  z-index: 999999999999 !important;
}
@media screen and (min-width:1150px){
  .margin-top{
    /* margin-top: 84px !important; */
    height: 860px;
    overflow: scroll;
  }
}
@media screen and (min-width:1800px){
  .margin-top{
    height: 960px;
  }
}
@media screen and (max-width:600px){
  .rate .mat-mdc-text-field-wrapper{
    padding-bottom: 0px!important;
}
  .mob{
    display: block;
  }
  .web{
    display: none;
  }
    p,span{
        font-weight: 300;
        font-size: 13px;
    } 
  .margin-top{
   overflow-x: hidden !important;
  }
  .btn-login,.btn-signup{
        padding: 12px 1.5rem !important;
  }
  .banner-section .mat-mdc-form-field {
    width: 90% !important;
    }
  .form-group{
    margin: 4px 2px !important;
  }
  label{
    font-size: 14px;
  }
  input{
    font-size: 14px;
  }
  }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.shadow {
  box-shadow: 0 .25rem 1rem rgba(0,0,0,.10) !important;
}


/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.banner-section .mat-mdc-text-field-wrapper {
  color: black !important;
}
.text-black
{
  color:black !important;
}
/* .banner-section .mat-form-field {
  width: 65% !important;
} */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.rate .mat-mdc-text-field-wrapper {
  color: black !important;
}
.mdc-label{
  font-size: 16px!important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-layout{
  padding-top: 26px !important;
}
.mat-mdc-slide-toggle {
  height: 36px !important;
}

.td-first{
  max-width: 170px;
  width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.td-two{
  max-width: 120px;
    width: 120px;
    padding-left: 12px;
}
.mdc-label{
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.offer-stu-card{
  margin: 12px 0;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  color: #000000DE !important;
  /* font-family: 'Montserrat'; */
  cursor: pointer;
  display: block;
}
.mat-expansion-panel-header{
  border-bottom: 1px solid;
}
.mat-expansion-panel-body {
  padding: 24px 16px !important;
}
.mat-expansion-panel-header-title {
  color: rgba(0,0,0,.87);
  font-size: 18px;
  font-weight: 600;
}
.offer-box{
  border: 1px solid black;
  width: 34px;
  text-align: center;
  border-radius: 26px;
  background: #80808042;
  color: black;
  padding: 3px 8px;
  margin-right: 20px;
  margin-left: 12px;
}
.btn-sender{
  padding: 12px 24px;
  border: 1px solid black;
  border-radius: 28px;
  line-height: 16px;
  color: #000;
  background-color: rgba(128, 128, 128, 0.553);
}
.mat-success{
  font-size: 16px;
  color: #10C7FC !important;
  border: 3px solid #10C7FC;
  font-weight: 700;
  padding:0.25rem;
  border-radius: 50%;
}
.mat-process{
  font-size: 26px;
  color: #fefe62 !important;
  display: none!important;
}
.mat-edit{
  color: black !important;
  position: absolute;
  right: 115px;
  font-size: 28px;
}
.amount{
  font-weight: 500;
}
.main-h2{
  font-size: 20px;
  margin-left: 24px;
  margin-bottom: 0;
}
.btn-pay{
  color: white !important;
}
.tag{
  color: #0070C0;
  font-size: 24px;
  font-weight: 500;
  font-family: 'Roboto';
}
.mat-process {
    display: none;
}
.dot {
  height: 11px;
  width: 11px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
.dot-selected{
  background-color: #0070C0;
}
.mat-icon{
  height:auto !important;
  width:auto !important;
}
.mat-expansion-panel-header[aria-disabled=true] {
  color: #333;
}
.text-lightblue{
  color:#3AA1D5;
}
.text-mediumblue{
  color:#10C7FC;
}
.text-darkblue{
  color:#0070C0;
}
.text-yellowblue{
  color:#FFA900;
}
.text-orangeblue{
  color:#ED7D31;
}
.bg-lightblue{
  background-color:#3AA1D5;
}
.bg-black{
  color:#000000;
}
.bg-mediumblue{
  background-color:#10C7FC;
}
.bg-darkblue{
  background-color:#0070C0;
}
.bg-yellowblue{
  background-color:#FFA900;
}
.bg-orangeblue{
  background-color:#ED7D31;
}
.bg-spacegrey{
  background-color: #E3E3E3;
}
.mat-expansion-panel{
  background-color: transparent;
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: transparent !important;
  border: 1px solid #707070 !important;
}
.mat-expansion-panel-header {
  border-bottom: 0px solid !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.signup .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  transform: translateY(-1.69375em) scale(.75) !important;
}
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: transparent;
}
.mat-expansion-panel-body {
  padding: 0px 16px 24px !important;
}
.display-4{
  font-weight: 500;
}
.mat-expansion-panel-header span b {
  color: #0070C0;
}
.mat-icon-content{
  position: absolute;
  right: 62px;
  font-weight: 500;
  color:#0070C0;
}
.bg-greycolor{
  background-color: #e6e6e6!important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
  padding: 0;
}
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}
/* Define a global style for mat-panel-description */
mat-panel-description {
  display: contents !important;
}
.mdc-label{
  margin-bottom: 0px !important;
}
.mdc-text-field--outlined {
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-outline-color: black;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-focus-outline-color: black;
  --mdc-outlined-text-field-container-shape: 6px !important;
  
}

.mat-content {
  display: inline-grid !important;
  flex: 1;
  flex-direction: row;
  overflow: hidden;
}
.mat-expansion-panel-header-title {
  color: var(--mat-expansion-header-text-color) !important;
}
.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-outline .mdc-notched-outline__notch {
  max-width: calc(100% - 24px) !important;
}
.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-right: 4px !important;
}
@media only screen and (max-width: 500px){
  .mat-content
  {
    display: flex !important;
    justify-content: center!important;
  }
.mat-icon-content
{
      position: relative;
    right: auto;
    font-weight: 500;
    color: #0070C0;
    display: flex !important;
}
.mat-expansion-panel-header-description {
  flex-grow: 0 !important;
}
}
@media only screen and (max-width: 500px){
.main-h2 {
  font-size: 15px;
  margin-left: 8px;
}
}
@media only screen and (max-width: 375px){
  .main-h2 {
    font-size: 15px;
    margin-left: 8px;
  }
  }
  @media only screen and (max-width: 320px){
    .main-h2 {
      font-size: 15px;
      margin-left: 8px;
    }
    .mat-edit {
     
      right: 78px;
  }
    }
